.navbar {
  width: 100%;
  height: 0px;
  padding: 0px;
}

.light_navbar {
  width: 100%;
  height: 64px;
  padding: 0px;
}

.navbar__shadow {
  width: 100%;
  height: 104px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

.light_navbar__container {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--white-color);
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.08);
  padding-inline: 2px;
}

.navbar__container {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding-inline: 2px;
  pointer-events: none;
}

.navbar__container_links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin: 8px;
  pointer-events: auto;
}

.navbar__hamburger {
  min-width: 48px;
  width: 48px;
  height: 48px;
  color: white;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_profile {
  width: 40px;
  height: 40px;
  color: white;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_icon {
  width: 22px;
  height: 22px;
}

.navbar_icon_small {
  width: 18px;
  height: 18px;
}


.navbar__logo {
  margin: 21px;
  /* width: 22px; */
  height: 24px;
  pointer-events: auto;
}

.navbar__opened {
  width: 100vw;
  height: calc(100vh);
  position: fixed;
  background-color: var(--primary-color);
  z-index: 110;
  top: 0;
  padding: 24px;
  padding-top: 84px;
}

.navbar__opened_h3_active {
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  z-index: 110;
}

.navbar__opened_h3_inactive {
  padding-top: 16px;
  padding-bottom: 16px;
  color: var(--medium-grey-color);
  position: relative;
  z-index: 110;
}

.navbar__opened_h3_logout {
  padding-top: 16px;
  padding-bottom: 16px;
  color: var(--medium-grey-color);
}

.navbar__button {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--white-color);
}

.light_navbar__button {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--primary-color);
}

.navbar__button_h4 {
  color: var(--primary-color);
  font-weight: 500;
}

.light_navbar__button_h4 {
  color: var(--white-color);
}