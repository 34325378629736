.icon_box {
  width: 72px;
  /* height: 72px; */
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.icon_box__rounded {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--light-grey-color);
  border-radius: 50%;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0, 0.16);
}


.icon_box__rounded_dark {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--light-grey-color);
  border-radius: 50%;
  box-shadow: 0 0 5px 0px rgb(0, 0, 0, 0.16);
}


.icon_box__transparent {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.icon_box__active {
  background-color: var(--white-color);
}

.icon_box__active_dark {
  background-color: var(--primary-color);
}

.icon_box__text_light {
  color: var(--light-grey-color);
  text-align: center;
  /* height: 32px; */
  overflow: hidden;
}

.icon_box__text_dark {
  color: var(--primary-color);
  text-align: center;
}

.icon_box__text_outlined_light {
  color: var(--medium-grey-color);
  text-align: center;
}

.icon_box__icon {
  height: 20px;
  width: 20px;
  fill: var(--light-grey-color);
  filter: invert(70%);
}

.icon_box__icon__rounded {
  height: 16px;
  width: 16px;
  fill: var(--light-grey-color);
}


.icon_box__icon__rounded_dark {
  height: 16px;
  width: 16px;
  fill: var(--dark-grey-color);
}

.icon_box__icon__active_dark {
  filter: invert(100%);
}

.icon_box__icon__transparent {
  height: 16px;
  width: 16px;
  fill: var(--light-grey-color);
}

.icon_box__icon__outlined_light {
  height: 16px;
  width: 16px;
  fill: var(--medium-grey-color);
}