.menu_component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  gap: 8px;
}

.menu_component__box {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.menu_component__box_content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

}

.menu_component__box_icon {
  width: 16px;
  height: 16px;
}

.menu_component__box_h5 {
  color: var(--dark-grey-color);
  font-weight: 400;
}