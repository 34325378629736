.process_detail_component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
  position: relative;
  gap: 16px;
}

.process_detail_component__box {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 24px;
  width: 100%;
  min-height: 40px;
}

.process_detail_component__box_box {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  background-color: var(--leading-color);
}

.process_detail_component__box_box_h4 {
  color: var(--white-color);
  margin-top: 1.5px;
  padding: 4px;
  padding-left: 4px;
}

.process_detail_component__box_h4 {
  padding: 4px;
  padding-left: 0px;
  color: var(--primary-color);
  margin-bottom: 4px;
}

.process_detail_component__box_p {
  color: var(--dark-grey-color);
}

.process_detail_component__box_box_image {
  margin-left: 11px;
  margin-top: 16px;
  width: 2px;
  height: 40px;
  /* background-color: var(--light-grey-color); */
}