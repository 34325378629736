.subscription_box {
  width: calc(100vw - 48px);
  height: calc((100vw - 48px) * 1.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
}

.subscription_box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  pointer-events: none;
  z-index: 2;
}

.subscription_box__box {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  padding: 24px;
}

.subscription_box__top_shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 176px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  pointer-events: none;
  z-index: 2;
}

.subscription_box__bottom_shadow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.subscription_box__box_title {
  padding-bottom: 8px;
}

.subscription_box__delivery_status {
  position: absolute;
  z-index: 3;
  bottom: 0px;
  padding: 24px;
  display: flex;
  width: 100%;
}

.subscription_box__price_bold {
  padding-left: 6px;
  /* font-weight: 600; */
}

@media screen and (min-width: 768px) {
  .subscription_box {
    width: 476px;
    height: 546px;
  }

  .subscription_box_box {
    padding: 60px;
  }
}
