.pdf_viewer {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000000 0%, rgb(50, 50, 50) 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
}

.pdf_viewer_container {
  width: 100%;
  max-width: 900px;
  margin-top: 64px;
  padding: 24px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
}

.pdf_viewer_container_page {

}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}