.filter_result_page {
  width: 100vw;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  background-color: var(--secondary-color); 
  -ms-overflow-style: none; 
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filter_result_page::-webkit-scrollbar {
  display: none;
}

.filter_result_page__container {
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
}

.filter_result_page__container_video {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.filter_result_page__results {
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: black;
}

.filter_result_page__results_head {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.filter_result_page__head_h2 {
  text-align: center;
}

.filter_result_page__head_h4 {
  color: var(--light-grey-color);
  text-align: center;
}

.filter_result_page__head {
  background-color: var(--primary-color);
}

.filter_result_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--dark-grey-color);
  position: relative;
  content: "";
  display: block;
  max-height: calc(100vh - 144px);
}

.filter_result_page__head_header::after {
  /* box-shadow: inset 0px -56px 56px 24px #000000; */
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}



.filter_result_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
}

.filter_result_page__head_header_shadow_right {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;  
  width: 100px;
  background: linear-gradient(left right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
}


.filter_result_page__head_header_image {
  height: calc(100vw - 5px);;
  width: 100vw; 
  object-fit: cover;
  z-index: 0;
  max-height: calc(100vh - 144px);
}

.filter_result_page__head_header_h1 {
  position: absolute;
  bottom: 16px; 
  left: 24px;
  right: 4px;  
  z-index: 2;
  color: var(--white-color); 
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}


.filter_result_page__head_header_icon {
  width: 20px;
  height: 20px;
  margin: 12px;
  margin-bottom: 18px;
}

.filter_result_page__head_box {
  padding: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.filter_result_page__head_box_h3 {
  color: var(--light-grey-color);
  padding-bottom: 16px;
}

.filter_result_page__body {
  padding: 24px;
}

.dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 84px;
  right: 24px;
  z-index: 10;
}

.dots__dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 4px 0px;
}

.filter_result_page__body_icon {
  width: 24px;
  height: 24px;
}