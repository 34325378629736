.choose_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 24px;
}

.choose_input__button {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--medium-grey-color);
  padding: 1px;
  color: var(--white-color);
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.choose_input__button_selected {
  width: 100%;
  background-color: hsla(220, 14%, 75%, 0.16);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.choose_input__button_header {
  width: 100%;
  height: 48px;
  padding: 1px;
  color: var(--white-color);
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}


.choose_input__button__text {
  color: var(--primary-color);
}

.choose_input__button__description {
  color: var(--medium-grey-color);
  text-align: left;
  align-self: flex-start;
  padding-left: 48px;
  padding-right: 24px;
  padding-top: 0px;
  padding-bottom: 16px;
}

.choose_input__button__subdescription  {
  color: var(--primary-color);
  text-align: left;
  align-self: flex-start;
  padding-left: 48px;
  padding-right: 24px;
  padding-top: 0px;
  padding-bottom: 16px;
}

.choose_input__button__text_selected {
  color: var(--primary-color);
}

.choose_input__button__icon {
  width: 16px;
  height: 16px;
  margin: 16px;
}