.dashboard_page {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary-color);
}

.dashboard_page__header {
  height: 100%;
  width: 100%;
  padding: 24px;
  padding-bottom: 0px;
  padding-top: calc(64px + 24px); 
  display: flex;
  flex-direction: column;
  gap: 36px;
  background-color: var(--primary-color);
  overflow: hidden;
}

.dashboard_page__header_balance {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dashboard_page__header_balance_title {
  color: var(--light-grey-color);
}

.dashboard_page__header_trainer {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: calc(((100vw - 80px) * 0.75) / -2);
}

.dashboard_page__body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
}

.dashoard_page_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}