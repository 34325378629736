.small_trainer_box {
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
}

.small_trainer_box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  pointer-events: none; 
  z-index: 2;
}

.small_trainer_box__image {
  width: calc((100vw - 48px) * 0.75);
  height: calc((100vw - 48px) * 0.75); 
  right: 0;
  position: absolute; 
  object-fit: cover;
  z-index: 1;
}

.small_trainer_box__box {
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 0px;
  padding-left: 24px;
  padding-right: 24px;
  height: calc(96px - 20px - 20px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.small_trainer_box__left_shadow {
  content: "";
  position: absolute;
  top: 0;
  left: calc((100vw - 48px) * 0.25);
  right: 0;  
  width: 176px;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  border-left: 10px solid rgba(0, 0, 0, 1);
  pointer-events: none;
  z-index: 2;
}

.small_trainer_box__bottom_shadow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 176px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.small_trainer_box__box_title {
  width: 100%;
}

.small_trainer_box__box_description {
  width: 100%;
  color: var(--light-grey-color);
}

.small_trainer_box__contact_email {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 24px;
  display: flex;
  align-items: center;
}

.small_trainer_box__contact_phone {
  position: absolute;
  z-index: 3;
  bottom: 48px;
  left: 24px;
  display: flex;
  align-items: center;
}

.small_trainer_box__contact_icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}


/* light */

.light_small_trainer_box {
  width: calc(100vw - 80px);
  height: calc((100vw - 80px) * 0.75); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  background-color: #2d2d2d;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
}

.light_small_trainer_box__left_shadow {
  content: "";
  position: absolute;
  top: 0;
  left: calc((100vw - 80px) * 0.25);
  right: 0;  
  width: 176px;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #2d2d2d);
  border-left: 10px solid #2d2d2d;
  pointer-events: none;
  z-index: 2;
}

.light_small_trainer_box__image {
  width: calc((100vw - 80px) * 0.75);
  height: calc((100vw - 80px) * 0.75); 
  right: 0;
  position: absolute; 
  object-fit: cover;
  z-index: 1;
}

.light_small_trainer_box__box_title {
  width: 100%;
  padding-bottom: 12px;
}

.light_small_trainer_box__box_description {
  width: 100%;
  color: var(--light-grey-color);
}