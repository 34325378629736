.product_box_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc((100vw - 48px - 24px) / 2), 24px));
  grid-auto-rows: 1fr;
  gap: 24px;
  width: calc(100% + 48px);
  padding-left: 24px; 
  padding-right: 24px;
  margin-left: -24px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: -24px;
  margin-bottom: -24px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.product_box_grid::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}

@media screen and (min-width: 768px) {
  .product_box_grid {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }


  @media screen and (min-width: 1248px) {
    .product_box_scroll {
      display: grid;
      /* grid-template-columns: repeat(auto-fill, minmax(282px, 1fr)); */
      gap: 24px;
    }
  }
  
}