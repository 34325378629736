.bottom_navbar {
  width: 100%;
  height: 80px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  position: fixed;
  bottom: 0;
  z-index: 10;
}

.bottom_navbar__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  height: 40px;
  width: 200px;
}

.bottom_navbar__right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.bottom_navbar__left_h3 {
  color: var(--primary-color);
}

.bottom_navbar__left_h4 {
  color: var(--dark-grey-color);
  text-align: end;
}


.bottom_navbar__right_box {
  height: 48px;
  width: 140px;
}

.bottom_navbar__right_button {
  background-color: var(--leading-color);
  color: var(--white-color);
  height: 48px;
  width: 140px;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.bottom_navbar__right_button_h3 {

}

.bottom_navbar_progress__left_button_h3 {
  color: var(--primary-color);
}

.bottom_navbar__right_button_disabled_h3 {
  color: var(--dark-grey-color);

}

.bottom_navbar_progress__left_button {
  background-color: transparent;
  color: var(--primary-color);
  height: 48px;
  width: 140px;
  border: none;
  border-radius: 8px;
  padding: 8px;
  padding-left: 0px;
  text-align: start;
  cursor: pointer;
}

.bottom_navbar_progress__right_button {
  background-color: var(--primary-color);
  color: var(--white-color);
  height: 48px;
  width: 140px;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer; 
}

.bottom_navbar_progress__right_button_disabled {
  background-color: var(--light-grey-color);
  color: var(--dark-grey-color);
  height: 48px;
  width: 140px;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.bottom_navbar_double {
  width: 100%;
  height: 80px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  gap: 24px;
}


@media screen and (min-width: 768px) {



  @media screen and (min-width: 1248px) {
    .bottom_navbar {
      left: calc(50% - 600px);
      max-width: 1200px;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px 0px 24px 24px rgba(0, 0, 0, 0.08);
    }

  }
}
