.base_modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  align-self: center;
  z-index: 1001;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 1200px;
  min-height: 300px; /* Corrected: removed duplicate min-height */
  background-color: var(--white-color);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -4px 16px 4px rgba(0, 0, 0, 0.16);
}

.base_modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 24px;
  padding: 24px;
}

.base_modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}


@media (min-width: 768px) {
  .base_modal__container {
    padding: 48px;
    padding-left: 96px;
    padding-right: 96px;
  }
}

@media (min-width: 1200px) {
  .base_modal {
    left: calc(50% - 600px);
  }

}
