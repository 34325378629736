.text_input_modal__box {
  width: 100%;
  height: 100%;
  gap: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text_input_modal__box_texts {
  gap: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.text_input_modal__title {
  color: var(--primary-color);
  text-align: center;
}

.text_input_modal__subtitle {
  color: var(--dark-grey-color);
  text-align: center;
}