.product_box {
  width: calc(100vw * 0.70);
  height: calc((100vw * 0.70) * 1.33);
  max-width: 282px;
  max-height: calc(282px * 1.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  /* box-shadow: 0px 0px 12px 4px #e91345; */
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  background-color: var(--primary-color);
}

.product_box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  pointer-events: none; 
  z-index: 2;
}

.product_box__image {
  position: relative; 
  height: 272px;
  width: 204px; 
  object-fit: cover;
  z-index: 1;
  background-color: var(--dark-grey-color);
}

.product_box__box {
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.product_box__top_shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70%;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  pointer-events: none;
  z-index: 2;
}

.product_box__bottom_shadow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.product_box__box_title {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 12px;
}

.product_box__price {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 24px;
}

.product_box__price_text {
  margin-right: 6px;
  color: var(--light-grey-color);
}

.product_box__price_bold {
  color: var(--white-color);
}

.small_product_box {
  width: calc((100vw - 48px - 24px) / 2);
  height: calc(((100vw - 48px - 24px) / 2) * 1.33);
  /* max-width: 282px;
  max-height: calc(282px * 1.33); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
}

.small_product_box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; 
  box-shadow: inset 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  pointer-events: none; 
  z-index: 2;
}

.small_product_box__box {
  position: absolute;
  z-index: 3;
  top: 18px;
  left: 0px;
  padding-left: 20px;
  padding-right: 20px;
}


.small_product_box__box_title {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 12px;
}

.small_product_box__top_shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70%;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  pointer-events: none;
  z-index: 2;
}

.small_product_box__bottom_shadow {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 2;
}

.small_product_box__price {
  position: absolute;
  z-index: 3;
  bottom: 18px;
  left: 20px;
  display: flex;
}

.product_box__recomended {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  right: 24px;
  background-color: var(--leading-color);
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
  border-radius: 4px;;
}

.product_box__recomended_icon {
  width: 12px;
  height: 12px;
}

.product_box__recomended_text {
  color: var(--white-color);
}

.product_box_empty_text {
  color: var(--light-grey-color);
  padding-top: 16px;
}


@media screen and (min-width: 768px) {

  @media screen and (min-width: 1248px) {
    .small_product_box {
      width: 100%;
      height: auto;
      aspect-ratio: 3 / 4;
    }
  }

}
