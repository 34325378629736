.shop_page {
  padding-top: 95px;
  height: 100%;
  width: 100%;
}


.shop_page__head {
  background-color: var(--primary-color);
  height: calc(100vh - 200px + 41px);
}

.shop_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--dark-grey-color);
  position: relative;
  max-height: calc(100vh - 200px);
}

.shop_page__head_header_shadow_top {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 200px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  pointer-events: none;
  z-index: 1;
}

.shop_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
}

.shop_page__head_header_image {
  height: calc(100vw);
  width: 100vw; 
  object-fit: cover;
  z-index: 0;
  max-height: calc(100vh - 200px);
}

.shop_page__head_header_h2 {
  position: absolute;
  top: 24px; 
  left: 24px;
  right: 24px;  
  z-index: 2;
}

.shop_page__head_header_h4 {
  position: absolute;
  top: 68px; 
  left: 24px;
  right: 24px;  
  z-index: 2;
  color: var(--light-grey-color);
}

.shop_page__head_header_button {
  position: absolute;
  bottom: 24px; 
  left: 24px;
  right: 24px;  
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.shop_page__head_header_button_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shop_page__body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  background-color: var(--secondary-color);
}

.shop_page__body_box_trainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.shop_page__body_box_trainer_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding-bottom: 16px;
}

.shop_page__body_box_trainer_box_title {
  color: var(--primary-color);
}

.shop_page__body_box_trainer_box_description {
  color: var(--dark-grey-color);
  margin-top: -8px;
}

.shop_page__body_box_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  margin-top: -8px;
}

@supports (height: 100svh) {
  .shop_page__head {
    height: calc(100svh - 200px + 41px);
  }
  
  .shop_page__head_header {
    max-height: calc(100svh - 200px);
  }

  .shop_page__head_header_image {
    max-height: calc(100svh - 200px);
  }
  
}


@media screen and (min-width: 768px) {
  
  .shop_page {
    display: flex;
    flex-direction: row;
    width: 100%;
  }


  .shop_page__head {
    background-color: var(--primary-color);
    height: calc(100vh - 200px + 41px);
    width: 33%;
    position: sticky;
    top: calc(96px + 64px);
    z-index: 0; /* Ensure it stays on top of other content */
  }

  .shop_page__body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 67%;
    min-height: calc(100vh - 200px + 41px + 96px);
    margin-top: -96px;
    padding-top: 120px;
  }

  .shop_page__head_header {
    width: 100%;
    position: sticky;
  }

  .shop_page__head_header_image {
    height: 100%;
    width: 100%;
  }

  .shop_page__head_header_button {
    width: calc(33vw - 48px);
  }

  @media screen and (min-width: 1248px) {

    .shop_page__body_box_trainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
      gap: 24px;
    }


  }

}
