.trainer_profile_page {
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  padding-bottom: 4px;
}

.trainer_profile_page__head {
  background-color: var(--primary-color);
}

.trainer_profile_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--dark-grey-color);
  position: relative;
}

.trainer_profile_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
}

.trainer_profile_page__head_header_image {
  height: calc(100vw - 5px);;
  width: 100vw; 
  object-fit: cover;
  z-index: 0;
}

.trainer_profile_page__head_header_h1 {
  position: absolute;
  bottom: 16px; 
  left: 24px;
  right: 4px;  
  z-index: 2;
  color: var(--white-color); 
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}


.trainer_profile_page__head_header_icon {
  width: 20px;
  height: 20px;
  margin: 12px;
  margin-top: 6px;
}

.trainer_profile_page__head_box {
  padding: 24px;
  padding-top: 4px;
  padding-bottom: 24px;
}

.trainer_profile_page__head_box_h4 {
  color: var(--light-grey-color);
  padding-bottom: 16px;
}

.trainer_profile_page__head_box_button {
  margin-top: 16px;
}

.trainer_profile_page__head_box_show_more {
  padding-top: 8px
}

.trainer_profile_page__head_box_show_more_h2 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.trainer_profile_page__head_box_show_more_h4 {
  color: var(--light-grey-color);
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: pre-line;
}

.trainer_profile_page__body {
  background-color: var(--secondary-color);
  margin: 24px;
}

.trainer_profile_page__body_h2 {
  color: var(--primary-color);
  padding-bottom: 8px;
  margin-top: 24px;
}

.trainer_profile_page__body_h4 {
  color: var(--dark-grey-color);
  text-align: justify;
  padding-top: 24px;
  padding-bottom: 24px;
}

.trainer_profile_page__head_header_shadow_desktop {
  display: none;
}

.trainer_profile_page__head_box_show_more_desktop {
   display: none; 
}

@media screen and (min-width: 768px) {
  .trainer_profile_page__head_box_show_less {
    display: none;
  }

  .trainer_profile_page__head_box_show_more {
    display: none;
  }

  .trainer_profile_page__head_box_button {
    display: none;
  }

  .trainer_profile_page__head_header {
    height: calc(100vh - 80px);
    width: 100vw;
    overflow: hidden;
  }

  .trainer_profile_page__head_header_image {
    /* height: calc(100vh - 80px); */
    width: 100vw;
  }

  .trainer_profile_page__head_header_shadow {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 500px;  
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }

  .trainer_profile_page__head_header_shadow_desktop {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;  
    width: 50%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }

  .trainer_profile_page__head_header_h1 {
    position: absolute;
    top: 40%; 
    left: 64px;
    width: 100%;
    z-index: 2;
    color: var(--white-color); 
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .trainer_profile_page__head_box_h4 {
    position: absolute;
    top: calc(40% + 32px); 
    left: 64px;
    width: 50%;
    z-index: 2;
    color: var(--white-color); 
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .trainer_profile_page__head_box {
    padding: 64px;
    padding-top: 0px;
  }

  .trainer_profile_page__head_box_show_more_desktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 48px;
  }

  .trainer_profile_page__head_box_show_more_desktop_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 64px;
    padding-bottom: 48px;
  }

  .trainer_profile_page__head_box_show_more_desktop_box_box {
    width: 33vw;
  }

  .trainer_profile_page__head_box_show_more_h4 {
    width: 50%;
  }

  .trainer_profile_page__body {
    margin: 64px;
  }

  .trainer_profile_page__body_h4 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media screen and (min-width: 1248px) {
    .trainer_profile_page__head_header_h1 {
      left: calc(50% - 600px);
    }
    .trainer_profile_page__head_box_h4 {
      left: calc(50% - 600px);
    }

    .trainer_profile_page__head_box{
      padding-left: calc((100vw - 1200px) / 2);
      padding-right: calc((100vw - 1200px) / 2);
    }    

    .trainer_profile_page__body {
      margin-left: calc(50vw - 600px - 7px);
      max-width: 1200px;
    }
  }

}
