.login_bottom_modal {
  width: 100%;
  height: 350px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.login_bottom_modal_layout {
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.login_bottom_modal__title {
  gap: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.login_bottom_modal__title_h2 {
  color: var(--primary-color);
}

.login_bottom_modal__title_h3 {
  color: var(--dark-grey-color);
}

.login_bottom_modal__separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: -4px;
  margin-bottom: -4px;
}

.login_bottom_modal__separator_line {
  flex-grow: 1;
  background-color: var(--light-grey-color); /* Adjust the color as needed */
  height: 1px; /* Adjust thickness as needed */
  margin: 0 10px; /* Spacing around the text */
}

.login_bottom_modal__separator_text {
  padding: 0 5px;
  color: var(--medium-grey-color);
  /* Additional styling for the text like font-size, color, etc., as needed */
}

.login_bottom_modal__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  height: 350px;
}

.loggin_bottom_modal__icon {
  width: 64px;
  height: 64px;
}

.login_bottom_modal__email_h3 {
  color: var(--dark-grey-color);
  padding-top: 40px;
  padding-bottom: 40px;
}

.login_bottom_modal__large {
  width: 100%;
  height: calc(100vh - 64px - 48px);
  min-height: 380px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}

.login_bottom_modal__title_large {
  gap: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* Media query for larger screens like desktops */
@media screen and (min-width: 1024px) {
  .login_bottom_modal {
    max-width: 60%;
  }
}
