.picker_input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: baseline; */
  gap: 16px; 
}

.picker_input_box {
  width: 96px;
  height: 96px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.picker_input_box_box {
  width: 96px;
  min-height: 96px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--medium-grey-color); 
  border-radius: 8px;
}

.picker_input_box_box_active {
  background-color: rgb(183 189 201 / 0.16);
  border: 2px solid var(--primary-color);
}

.picker_input_box_box_empty {
  width: 96px;
  min-height: 96px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 2px dashed var(--medium-grey-color); 
  border-radius: 8px;
}

.picker_input__icon__empty {
  width: 20px;
  height: 20px;
  color: var(--medium-grey-color);
}

.picker_input_box__text_light {
  color: var(--dark-grey-color);
  text-align: center;
}

.picker_input_box__text_dark {
  color: var(--primary-color);
  text-align: center;
}

.picker_input__icon {
  height: 20px;
  width: 20px;
  fill: var(--light-grey-color);
}