.content_box {
  min-width: 136px;
  min-height: 136px;
  max-height: 213px;
  max-width: 213px;
  width: calc(100vw / 2 - 12px - 24px);
  height: calc(100vw / 2 - 12px - 24px);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  border-radius: 8px;
  background-color: var(--dark-grey-color);
  overflow: hidden;
}

.content_box__icon {
  height: 40px;
  width: 40px;
  margin: 16px;
  margin-bottom: 0px;
}


.content_box__h3 {
  margin: 16px;
  width: calc(100% - 32px);
  word-break: break-all;
}


.content_box_outlined_light {
  min-width: 136px;
  min-height: 136px;
  max-height: 213px;
  max-width: 213px;
  width: calc(100vw / 2 - 12px - 24px);
  height: calc(100vw / 2 - 12px - 24px);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
  border: 2px solid var(--medium-grey-color);
}

.content_box_outlined_light__upload {
  min-width: 136px;
  min-height: 136px;
  max-height: 213px;
  max-width: 213px;
  width: calc(100vw / 2 - 12px - 24px);
  height: calc(100vw / 2 - 12px - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
  border: 2px dashed var(--medium-grey-color);
}

.content_box_outlined_light__icon {
  height: 40px;
  width: 40px;
  margin: 16px;
  margin-bottom: 0px;
}


.content_box_outlined_light__h3 {
  margin: 16px;
  width: calc(100% - 32px);
  color: var(--dark-grey-color);
  text-align: center;
}
