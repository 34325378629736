.input_form {
  background-color: #4d4e52;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  color: white;
  font-size: 14px;
  padding-left: 1rem;
}

.input_form::placeholder {
  color: #b8bdc5;
  font-size: 14px;
}

.input_form:focus {
    outline: none !important;
    border:1px solid #FF9900;
    box-shadow: 0 0 10px #719ECE;
  }

.long_text_input__container {
  width: 100%;
  height: 60px;
}

.long_text_input__outlined_light {
  border: 1px solid var(--medium-grey-color);
  border-radius: 8px;
  width: 100%;
  height: 120px;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  padding: 1px;
}

.long_text_input__outlined_light_focused {
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  width: 100%;
  height: 120px;
  color: white;
  font-size: 14px;
  overflow: hidden;
  display: flex;
}

.long_text_input__outlined_light_error {
  border: 1px solid var(--leading-color);
  border-radius: 8px;
  width: 100%;
  height: 120px;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  padding: 1px;
}

.long_text_input__outlined_light__input_form {
  height: 100%;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  display: flex;
  border: none;
  color: var(--dark-grey-color);
  font-size: 16px;
  letter-spacing: 1px;
  font-family: 'Roboto Flex', sans-serif;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: transparent;
  white-space: pre-wrap;
  overflow: auto;
  resize: none; 
}

.long_text_input__outlined_light__input_form::placeholder {
  color: var(--light-grey-color);
  font-size: 16px;
  letter-spacing: 1px;
  font-family: 'Roboto Flex', sans-serif;
}

.long_text_input__outlined_light__input_form:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.long_text_input__outlined_light__input_form::-webkit-scrollbar {
  display: none; /* Hides scrollbar for Webkit browsers */
}

.long_text_input__outlined_light__icon {
  width: 16px;
  height: 16px;
  margin: 14px;
  fill: var(--medium-grey-color);
}

.long_text_input__outlined_light__error_text {
  color: var(--leading-color);
  font-size: 12px;
  margin: 8px;
  margin-left: 0px;
  text-align: left;
}

.long_text_input__outlined_light__underline_text {
  color: var(--dark-grey-color);
  font-size: 12px;
  margin: 8px;
  margin-left: 0px;
  text-align: left;
}