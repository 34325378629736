.empty_small_trainer_box {
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border: 2px dashed #000;
}

.empty_small_trainer_box__box_h5 {
  color: var(--dark-grey-color);
  text-align: center;
}