.subscription_detail_page {
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
}

.subscription_detail_page__head {
  background-color: var(--primary-color);
}

.subscription_detail_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--dark-grey-color);
  position: relative;
}

.subscription_detail_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  border-bottom: 10px solid rgba(0, 0, 0, 1);
  pointer-events: none;
  z-index: 1;
}

.subscription_detail_page__head_header_image {
  height: calc(100vw - 5px);;
  width: 100vw;
  object-fit: cover;
  z-index: 0;
}

.subscription_detail_page__head_header_h1 {
  position: absolute;
  bottom: 16px;
  left: 24px;
  right: 24px;
  z-index: 2;
  color: var(--white-color);
}

.subscription_detail_page__head_box {
  padding: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.trainer_profile_page__head_box_h4 {
  color: var(--light-grey-color);
  padding-bottom: 16px;
  text-align: justify;
}

.subscription_detail_page__head_box_button {
  margin-top: 12px;
  margin-bottom: 24px;
}

.subscription_detail_page__head_box_show_more {
  padding-top: 8px;
}

.subscription_detail_page__head_box_show_more_box {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.subscription_detail_page__head_box_show_more_h2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.subscription_detail_page__head_box_show_more_h4 {
  color: var(--light-grey-color);
  text-align: justify;
  padding-top: 8px;
  padding-bottom: 8px;
}

.subscription_detail_page__body {
  background-color: var(--secondary-color);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.subscription_detail_page__body_box {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 24px;
}

.subscription_detail_page__body_h2 {
  color: var(--primary-color);
}

.subscription_detail_page__trainer_h2 {
  color: var(--primary-color);
  /* margin-bottom: 16px; */
}