.admin_list_page {
  padding-top: 95px;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
}


.admin_list_page__body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.admin_list_page__body_box_trainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.admin_list_page__body_box_trainer_box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  background-color: var(--light-grey-color);
  border-radius: 8px;
}