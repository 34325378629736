.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  gap: 32px;
}

.description__box {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 24px;
  width: 100%;
}

.description__box_box {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  background-color: var(--leading-color);
}

.description__box_h3 {
  padding-bottom: 8px;
  color: var(--primary-color);
}

.description__box_h4 {
  color: var(--dark-grey-color);
  white-space: pre-line;
}

.description__box_icon {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
}


.description_half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 32px;
}

.description_half__box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}