.page {
  height: 100%;
  width: 100%;
  margin-top: -64px;
  padding-top: 64px;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
}

.page__layout {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page__title {
  color: var(--primary-color);
  text-align: center;
}

.page__subtitle {
  color: var(--dark-grey-color);
  text-align: center;
  padding-bottom: 24px;
}

@media screen and (min-width: 768px) {
  
  @media screen and (min-width: 1248px) {
    .page {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

}