.home_page {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home_page::-webkit-scrollbar {
  display: none;
}

.home_page__container {
  height: 100vh;
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: black;
  pointer-events: all;
  overflow: "hidden";
}

.home_page__container_video_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  pointer-events: all;
  z-index: 2;
  background-color: transparent;
}

.home_page__container_video {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 1;
}

.home_page__box {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 10;
  text-align: left;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  padding-top: 260px;
  margin-top: -260px;
}

.home_page__box_h3 {
  color: var(--light-grey-color);
  font-weight: 300;
}

.home_page__box_buttons {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  pointer-events: none;
  z-index: 10;
}


@media screen and (min-width: 768px) {
  .home_page__container_video {
    width: 100%;
    object-fit: cover;
  }
  .home_page__box {
    width: 50%;
    height: 100%;
    justify-content: center;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    padding-left: 64px;
    padding-right: calc(10% + 64px);
  }

  .home_page__box_h3 {
    margin-top: 3rem;
  }
  .home_page__box_buttons {
    justify-content: center;
    margin-bottom: 8rem;
  }
}
