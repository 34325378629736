.payment_card {
  width: 100%;
  height: 48px;
  background-color: var(--dark-grey-color);
  border-radius: 8px;
  display: flex;
  justify-content: left;
  gap: 0px;
}

.payment_card_brand_icon {
  width: 40px;
  height: 32px;
  margin: 8px;
  float: left;
}

.payment_card__box {
  margin: 8px;
  height: 32px;
}

.payment_card_box__box_h4 {
  margin-top: -4px;
  color: var(--light-grey-color);
}

.payment_card__delete_icon_container {
  position: absolute;
  height: 32px;
  width: 40px;
  margin: 8px;
  padding-left: 8px;
  border-left: solid 1px var(--medium-grey-color);
  right: 24px;
}

.payment_card_delete_icon {
  width: 48px;
  height: 48px;
  margin: -8px;
}

.payment_card_light {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: left;
  gap: 0px; 
  border: solid 1px var(--medium-grey-color);
}


.payment_card_box__box_h3_light {
  color: var(--primary-color);
}

.payment_card_box__box_h4_light {
  margin-top: -4px;
  color: var(--medium-grey-color);
}

.payment_card__delete_icon_container_light {
  position: absolute;
  height: 32px;
  width: 40px;
  margin: 8px;
  padding-left: 8px;
  border-left: solid 1px var(--medium-grey-color);
  right: 24px;
}