.article_detail_page {
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  padding-bottom: 8px;
}

.article_detail_page__head {
  background-color: var(--primary-color);
}

.article_detail_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--dark-grey-color);
  position: relative;
}

.article_detail_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  border-bottom: 10px solid rgba(0, 0, 0, 1);
  pointer-events: none;
  z-index: 1;
}

.article_detail_page__head_header_image {
  height: calc(100vw - 5px);
  width: 100vw;
  object-fit: cover;
  z-index: 0;
}

.article_detail_page__head_header_h1 {
  position: absolute;
  bottom: 16px;
  left: 24px;
  right: 24px;
  z-index: 2;
  color: var(--white-color);
}

.article_detail_page__head_box {
  padding: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.article_detail_page__head_box_h4 {
  color: var(--light-grey-color);
  padding-bottom: 16px;
  white-space: pre-line;
}

.article_detail_page__head_box_button {
  margin-top: 12px;
  margin-bottom: 24px;
}

.article_detail_page__head_box_show_more {
  padding-top: 8px;
}

.article_detail_page__head_box_show_more_h2 {
  padding-top: 8px;
  padding-bottom: 12px;
}

.article_detail_page__head_box_show_more_h4 {
  color: var(--light-grey-color);
  text-align: justify;
  padding-top: 8px;
  padding-bottom: 8px;
}

.article_detail_page__body {
  background-color: var(--secondary-color);
  padding: 24px;
}

.article_detail_page__body_h2 {
  color: var(--primary-color);
  padding-bottom: 24px;
}


.article_detail_page__body_trainer_h2 {
  color: var(--primary-color);
  padding-top: 16px;
  padding-bottom: 24px;
}

.article_detail_page__head_box_show_more_desktop {
  display: none;
}


@media screen and (min-width: 768px) {
  .article_detail_page__head_box_show_less {
    display: none;
  }

  .article_detail_page__head_box_show_more {
    display: none;
  }

  .article_detail_page__head_box_button {
    display: none;
  }

  .article_detail_page__head_header {
    height: calc(100vh - 80px);
    width: 100vw;
    overflow: hidden;
  }

  .article_detail_page__head_header_image {
    width: 100vw;
  }

  .article_detail_page__head_header_shadow {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 500px;  
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }

  .article_detail_page__head_header_shadow_desktop {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;  
    width: 50%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }

  .article_detail_page__head_header_h1 {
    position: absolute;
    top: 40%; 
    left: 64px;
    width: 100%;
    z-index: 2;
    color: var(--white-color); 
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .article_detail_page__head_box_h4 {
    position: absolute;
    top: calc(40% + 32px); 
    left: 64px;
    width: 40%;
    z-index: 2;
    color: var(--white-color); 
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .article_detail_page__head_box {
    padding: 64px;
    padding-top: 0px;
  }

  .article_detail_page__head_box_show_more_desktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 48px;
  }

  .article_detail_page__head_box_show_more_desktop_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 64px;
    padding-bottom: 48px;
  }

  .article_detail_page__head_box_show_more_h4 {
    width: 50%;
  }

  .article_detail_page__body {
    margin: 64px;
  }

  .article_detail_page__body_h4 {
    padding-top: 64px;
    padding-bottom: 64px;
  }


  .article_detail_page__body_trainer_h2 {
    color: var(--primary-color);
    padding-top: 64px;
    padding-bottom: 24px;
  }

  @media screen and (min-width: 1248px) {
    .article_detail_page__head_header_h1 {
      left: calc(50% - 600px);
    }
    .article_detail_page__head_box_h4 {
      left: calc(50% - 600px);
    }

    .article_detail_page__head_box{
      padding-left: calc((100vw - 1200px) / 2);
      padding-right: calc((100vw - 1200px) / 2);
    }    

    .article_detail_page__body {
      max-width: 1200px;
      margin-left: calc(50vw - 600px - 7px);
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }  
  }

}
