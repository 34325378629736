.login_page_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.login_page_title {
  font-size: 32px;
}

.login_page_form {
  margin-top: 2rem;
}

.login-page_message_container {
margin-top: 1rem;
}

.login-page_message {
  border-radius: 8px;
  background-color: #292000;
  width: 284px;
  text-align: center;
}

.login-page_message p {
  padding: 15px;
  color: #ffc700;
}

.login_page_bottom {
  margin-top: 4rem;
}

.login_page_other_links {
  color: #b8bdc5;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  margin-left: 1.5rem;
}

.login_page_link {
  color: #b8bdc5;
  font-size: 12px;
}
