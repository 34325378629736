#root {
  height: 100%;
}

.App {
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  background-color: black;
  height: 100%;
  width: 100%;
  margin: 0px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

a {
  text-decoration: underline;
  color: var(--primary-color);
  font-weight: 600;
}

h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 1px;
    color: var(--white-color);
    font-family: 'Helvetica Neue', sans-serif;
    margin: 0px;
    margin-bottom: -3px;
}

h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 1px;
    color: var(--white-color);
    font-family: 'Helvetica Neue', sans-serif;
    margin: 0px;
    margin-bottom: -3px;
}

h3 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  color: var(--white-color);
  font-family: 'Helvetica Neue', sans-serif; 
  margin: 0px;
  margin-bottom: -3px;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    color: var(--white-color);
    font-family: 'Helvetica Neue', sans-serif;
    margin: 0px;
    margin-bottom: -3px;
}

h5 { 
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  color: var(--white-color);
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0px;
  margin-bottom: -3px;
}


p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-weight: 1px;
  letter-spacing: 0.5px;
  color: var(--light-grey-color);
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0px;
  margin-bottom: -2px;
}

/* ::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--white-color);
} */

