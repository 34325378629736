.trainer_welcome_page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-height: calc(100vh - 64px);
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.trainer_welcome_page__head {
  background-color: var(--primary-color);
  height: calc(100vw + 140px);
  max-height: calc(100vh - 144px);
}

.trainer_welcome_page__head_header {
  height: 100vw;
  width: 100vw;
  background-color: var(--primary-color);
  position: relative;
  max-height: calc(100vh - 144px - 140px);
}

.trainer_welcome_page__head_header_shadow_top {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 200px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  pointer-events: none;
  z-index: 1;
}

.trainer_welcome_page__head_header_shadow {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;  
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: 1;
}

.trainer_welcome_page__head_header_image {
  height: calc(100vw);
  width: 100vw; 
  object-fit: cover;
  z-index: 0;
  max-height: calc(100vh - 144px - 140px);
}

.trainer_welcome_page__head_header_h2 {
  position: absolute;
  top: 24px; 
  left: 24px;
  right: 24px;  
  z-index: 2;
}

.trainer_welcome_page__head_header_h2_pointing {
  position: absolute;
  top: 100px; 
  left: 24px;
  right: 24px;  
  z-index: 2;
  color: var(--leading-color);
}

.trainer_welcome_page__head_header_slider {
  position: relative;
  bottom: 12px;
  left: 24px;
  right: 24px;  
  height: 40px;
  z-index: 2;
  height: 75px;
  width: calc(100% - 48px);
}

.trainer_welcome_page__head_header_slider_h3 {
  color: var(--light-grey-color);
  margin-bottom: 8px;
}

.trainer_welcome_page__head_header_slider_h3_span {
  color: var(--white-color);
}

.trainer_welcome_page__body {
  margin-top: 8px;
  padding: 24px;
  padding-bottom: 0px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.trainer_welcome_page__body_box {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.trainer_welcome_page__body_box_image {
  height: 100%;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 48px;
  padding-right: 48px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trainer_welcome_page__body_box_image_bottom {
  height: 100%;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 48px;
  padding-right: 48px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  flex-direction: column;
  align-items: center;
}


.trainer_welcome_page__body_box_image_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 500px;
}

.trainer_welcome_page__body_questions {
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.trainer_welcome_page__h3 {
  text-align: center;
  color: var(--primary-color);
}

@media screen and (min-width: 768px) {

  .trainer_welcome_page__head_header_h2 {
    top: 48px;
  }

  .trainer_welcome_page__head_header_h2_pointing {
    top: 124px;
  }

  @media screen and (min-width: 1248px) {
    .trainer_welcome_page__head_header_h2 {
      left: calc(50% - 600px);
    }

    .trainer_welcome_page__head_header_h2_pointing {
      left: calc(50% - 600px);
    }

    .trainer_welcome_page__head_header_slider {
      max-width: 1200px;
      left: calc(50% - 600px);
    }

    .trainer_welcome_page__head_header_image {
      max-width: 1200px;
      margin-left: calc(50% - 600px);
    }

    .trainer_welcome_page__body {
      gap: 96px;
    }

    .trainer_welcome_page__body_box_image {
      margin-left: calc(-1 * (100vw - 1200px));
      margin-right: calc(-1 * (100vw - 1200px));
    }

    .trainer_welcome_page__body_box_image_bottom {
      margin-left:  calc(-1 * (100vw - 1200px));
      margin-right:  calc(-1 * (100vw - 1200px));
    }
  }
  
}
