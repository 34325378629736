.register_page {
  margin-top: -60px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  padding: 24px;
  padding-top: 84px;
  background-color: var(--secondary-color);
  gap: 24px;
}

.register_page_h2 {
  color: var(--primary-color);
  align-self: flex-start;
  text-align: left;
}

.register_page_consents {
  color: var(--dark-grey-color);
  text-align: justify;
}

.register_page_form {
  margin-top: 2rem;
}


.register-page_message {
  width: 100%;
}

.register-page_message p {
  color: var(--leading-color);
}

.register_page_bottom {
  margin-top: 4rem;
}

.register_page_other_links {
  color: #b8bdc5;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.register_page_link {
  color: #b8bdc5;
  font-size: 12px;
}

