.delivery_status_component {
  width: 100%;
}

.delivery_status_component__box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  gap: 0px;
  padding-top: 16px;
}

.delivery_status_component__h4 {
  color: var(--light-grey-color);
}

.delivery_status_component__h4_bold {
  /* font-weight: 500; */
  color: var(--white-color);
}

.delivery_status_component__h4_dark {
  color: var(--medium-grey-color);
}

.delivery_status_component__h4_bold_dark {
  color: var(--primary-color);
}