.cer_upload_page_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.cer_upload_page_title {
  font-size: 32px;
}

.cer_upload_page_sub-title {
  color: #b8bdc5;
  font-size: 16px;
  padding: 1rem;
}

.cer_upload_page_form {
  margin-top: 2rem;
}

.cer_upload_page_info {
  margin-top: 14px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 12px;
  color: #b7bdc9;
  text-align: left;
}

.cer_upload_page_bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: 5rem;
}
