.button_primary__outlined {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 2px solid var(--light-grey-color);
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: all;
}

.button_primary__outlined_light {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 2px solid var(--primary-color);
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: all;
}

.button_primary__main {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--leading-color);
  pointer-events: all;
}

.button_primary__h3__outlined_light {
  color: var(--primary-color);
}

.button_primary__outlined_light__icon {
  width: 16px;
  height: 16px;
  margin: 14px;
  fill: var(--medium-grey-color);
  margin-left: -14px;
}

.button_primary__disabled {
  opacity: 0.6;
}