.loading_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    gap: 24px;
    padding: 24px;
    background-color: black;
    position: fixed;
    z-index: 1000;
}

.loading_page__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    max-width: 1200px;
}

.loading_page__logo {
  height: 24px;
  pointer-events: auto;
}