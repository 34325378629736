.empty_subscription_box {
  width: calc(100vw - 48px);
  height: calc((100vw - 48px) * 1.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border: 2px dashed #000;

}

.empty_subscription_box__box {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.empty_subscription_box__box_image {
  width: 64px;
  height: 64px;
}

.empty_subscription_box__box_h3 {
  color: var(--dark-grey-color);
  text-align: center;
}

.empty_subscription_box__box_button {
  width: 160px;
}

@media screen and (min-width: 768px) {
  .empty_subscription_box {
    width: 476px;
    height: 546px;
  }

  .empty_subscription_box__box {
    padding: 60px;
  }
}
