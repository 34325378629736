.social_box_list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
  padding-top: 12px;
  padding-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px;
}

.social_box_list__box {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.social_box_list__box_icon {
  opacity: 0.75;
}

.social_box_list__box_left {
  display: flex;
  gap: 24px;
}

.social_box_list__box_h5 {
  color: var(--light-grey-color);
}