.switch {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 24px;
}

.switch_header {
  width: 100%;
  height: 96px;
  background-color: var(--white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px 24px;
  position: fixed;
  top: 63px;
  left: 0;
  z-index: 101;
  gap: 24px;
}

.switch_header__button {
  width: 100%;
  height: 64px;
  border: 1px solid var(--medium-grey-color);
  padding: 1px;
  color: var(--white-color);
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch_header__button_selected {
  width: 100%;
  height: 64px;
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch_header__button__text {
  color: var(--medium-grey-color);
  padding-top: 6px;
  letter-spacing: 0.5px;
}

.switch_header__button__text_selected {
  color: var(--primary-color);
  padding-top: 6px;
  letter-spacing: 0.5px;
}

.switch_header__button__icon {
  width: 16px;
  height: 16px;
}

.dinamic_switch {
  height: 72px;
  margin-left: -24px;
  margin-right: -24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 24px;
}


.dinamic_switch__container {
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 8px 24px 16px;
  gap: 24px; 
}



.dinamic_switch_header {
  width: 100%;
  height: 96px;
  background-color: var(--white-color);
  position: fixed;
  top: 63px;
  left: 0;
  z-index: 101;
}

.dinamic_switch_header__container {
  width: 100%;
  height: 96px;
  background-color: var(--white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 8px 24px 24px;
  top: 63px;
  left: 0;
  z-index: 101;
  gap: 24px; 
}

.dinamic_switch__container::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}


.dinamic_switch_header__container::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}



.switch_header__href {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid var(--medium-grey-color);
  padding-bottom: 1px;
  color: var(--white-color);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch_header__href_selected {
  width: 100%;
  height: 64px;
  border-bottom: 2px solid var(--primary-color);
  color: var(--white-color);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch_header__href__text {
  color: var(--medium-grey-color);
  padding-top: 6px;
  letter-spacing: 0.5px;
}

.switch_header__href__text_selected {
  color: var(--primary-color);
  padding-top: 6px;
  letter-spacing: 0.5px;
}

.switch_header__href__icon {
  width: 16px;
  height: 16px;
  fill: var(--light-grey-color);
  filter: invert(50%);
}

.switch_header__href__icon_selected {
  width: 16px;
  height: 16px;
}


@media screen and (min-width: 768px) {

  .dinamic_switch__container {
    gap: 64px;
  }

  @media screen and (min-width: 1248px) {
   
  }
  
}