/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueLight.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueHeavy.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/Helvetica/HelveticaNeueBlack.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --primary-color: #000000;
  --secondary-color: #F7F7FA;
  --leading-color: #E91345;
  /* --leading-color: #EA2C26; */
  --white-color: #FFFFFF;
  --light-grey-color: #B7BDC9;
  --medium-grey-color: #80858D;
  --dark-grey-color: #4D4C52;
}
