.subscription_list_page {
  padding-top: 95px;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
}

.subscription_list_page__layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

@media screen and (min-width: 768px) {
  .subscription_list_page__layout {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding: 24px;
  }


}
