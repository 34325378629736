.checkout_page {
  min-height: calc(100vh - 60px);
  width: 100vw;
  background-color: var(--secondary-color);
  padding: 24px;

}

.checkout_page__separator {
  height: 32px;
  width: 100%;
}

.checkout_page__h4 {
  color: var(--dark-grey-color);
  text-align: justify;
  padding-top: 24px;
  padding-bottom: 24px;
}