.payment_edit_page {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  padding: 24px;
  gap: 24px;
  background-color: var(--secondary-color);
  width: 100%;
}

.payment_edit_page__title {
  font-size: 32px;
  color: var(--primary-color);
}


.payment_edit_page__message_container {
  margin-top: 1rem;
}

.payment_edit_page__message {
  border-radius: 8px;
  background-color: #292000;
  width: 100%;
  text-align: center;
}

.payment_edit_page__message p {
  padding: 15px;
  color: #ffc700;
}

.payment_edit_page__bottom {
  margin-top: 4rem;
}

.payment_edit_page__other_links {
  color: #b8bdc5;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.payment_edit_page__link {
  color: #b8bdc5;
  font-size: 12px;
}

