.profile_page {
  margin-top: -60px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  padding: 24px;
  padding-top: 84px;
  background-color: var(--secondary-color);
  gap: 8px;
}

.profile_page__box {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.profile_page__box_content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

}

.profile_page__box_icon {
  width: 16px;
  height: 16px;
}

.profile_page__box_h5 {
  color: var(--dark-grey-color);
  font-weight: 400;
}

.profile_page__line {
  width: 100%;
  height: 1.5px;
  background-color: var(--light-grey-color);
}