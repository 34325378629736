.activation_status_component {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.activation_status_component__header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.activation_status_component__box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  padding: 0px;
}

.activation_status_component__h4 {
  color: var(--medium-grey-color);
}

.activation_status_component__h4_bold {
  /* font-weight: 500; */
  color: var(--primary-color);
}
