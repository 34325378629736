.common_questions_page {
  padding: 24px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.common_questions_page_h3 {
  color: var(--primary-color);
}