.privacy_container {
  margin-top: 5rem;
  text-align: center;
  background-color: var(--dark-grey-color);
}

.privacy_h1 {
  padding-top: 1rem;
  color: var(--secondary-color);
}

.privacy_pdf-viewer {
  margin-top: 2rem;
}
