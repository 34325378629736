.button_submit {
  color: white;
  background-color: var(--leading-color);
  font-size: 14px;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: .3s;
  font-size: 16px;
}

.button_submit:hover {
    background-color: rgb(192, 15, 56);
    transition: .3s;
}
