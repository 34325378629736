.product_box_scroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 16px;
  width: calc(100% + 48px);
  padding-left: 24px; 
  padding-right: 24px;
  margin-left: -24px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: -24px;
  margin-bottom: -24px;
}

.product_box_scroll::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
}

.product_box_scroll_empty {
  width: calc(100%);
  height: calc((100vw * 0.70) * 1.33);
  max-height: calc(282px * 1.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border: 2px dashed #000;
  padding: 40px
}

.product_box_scroll_empty_h5 {
  color: var(--dark-grey-color);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .product_box_scroll {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 16px;
    width: calc(100% + 128px);
    padding-left: 64px; 
    padding-right: 64px;
    margin-left: -64px;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: -64px;
    margin-bottom: -64px;
  }

  @media screen and (min-width: 1248px) {
    .product_box_scroll {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
      gap: 24px;
    }
  }
}