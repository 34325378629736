.package_list_page {
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-color: var(--secondary-color);
    padding-bottom: 60px;   
}

.package_list_page__layout {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
}