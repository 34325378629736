.trainer_affiliate_page {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: var(--secondary-color);
}

.trainer_affiliate_page_info_container {
  text-align: center;
  padding: 24px;
}


.trainer_affiliate_page_info_container_h3 {
  color: var(--primary-color);
}
.trainer_affiliate_page_info_container_h5 {
  color: var(--dark-grey-color);
}

.trainer_affiliate_page_back_button {
  height: 48px;
  font-weight: bold;
  padding: 0.78rem;
}
