.social_box {
  width: 72px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.social_box__icon {
  height: 20px;
  width: 20px;
}
