.filter_page {
  height: 100%;
  width: 100%;
  margin-top: -64px;
  padding-top: 64px;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
}

.filter_page__layout {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 104px;
}

.filter_page__title {
  color: var(--primary-color);
  text-align: center;
}

.filter_page__subtitle {
  color: var(--dark-grey-color);
  text-align: center;
  padding-bottom: 24px;
}