.footer {
  width: 100%;
  height: 100%;
  max-width: 900px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  gap: 24px;
  position: relative;
  z-index: 1;
}

.footer__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.footer__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: calc(50% - 24px);
  gap: 12px;
}

.footer__section_h5 {
  color: var(--white-color);
  margin-bottom: 4px;
  text-align: left;
}

.footer__section_p {
  color: var(--light-grey-color);
  text-align: left;
}

.footer__bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.footer_bottom_p {
  color: var(--light-grey-color);
  text-align: end;
}


/* Media query for larger screens like desktops */
@media screen and (min-width: 1024px) {
  .footer {
    padding: 64px;
    gap: 48px;
  }
  
  .footer__section {
    max-width: 150px;
    justify-content: center;
    margin-left: 12px;
  }
}
