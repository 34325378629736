.question {
  width: 100%;
  /* background-color: var(--white-color); */
  border-radius: 8px;
  padding: 20px;
  padding-left: 24px;
  padding-right: 24px;
  /* box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.08); */
  overflow: hidden;
  border: 1px solid var(--medium-grey-color);
}

.question__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.question__header_title {
  color: var(--primary-color);
  text-align: left;
  padding-right: 16px;
}

.question__description {

}

.question__description_p {
  color: var(--dark-grey-color);
  padding-top: 12px;
  font-weight: 300;
}