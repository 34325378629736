.profile_edit_info_page__container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  padding: 24px;
  gap: 24px;
  background-color: var(--secondary-color);
  width: 100%;
}

.profile_edit_info_page__title {
  color : var(--primary-color);
}

.profile_edit_info_page__form {
  width: 100%;
  gap: 24px;
  display: flex; 
  flex-direction: column;
}

.profile_edit_info_page__message_container {
  margin-top: 1rem;
}

.profile_edit_info_page__message {
  border-radius: 8px;
  background-color: #292000;
  width: 100%;
  text-align: center;
}

.profile_edit_info_page__message p {
  padding: 15px;
  color: #ffc700;
}

.profile_edit_info_page__bottom {
  margin-top: 4rem;
}

.profile_edit_info_page__other_links {
  color: #b8bdc5;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.profile_edit_info_page__link {
  color: #b8bdc5;
  font-size: 12px;
}

.profile_edit_info_page_message {
  width: 100%;
}

.profile_edit_info_page_message p {
  color: var(--leading-color);
}